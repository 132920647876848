import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Kaspersky Internet Security 2014",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2014/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_Kaspersky14.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_Kaspersky14.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Kaspersky 2014' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/Kaspersky_2014/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2014/' crumbLabel="Kaspersky" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-an-exception-rule-inside-your-kaspersky-2014",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-an-exception-rule-inside-your-kaspersky-2014",
        "aria-label": "set an exception rule inside your kaspersky 2014 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set an Exception rule inside your Kaspersky 2014`}</h2>
    <p>{`In some cases, it can be that the virus scanner Kaspersky is blocking the video stream of the camera. Actually it blocks the ActiveX and Quicktime Plugin from receiving the cameras video stream. In this case you will need to set an exception in Kaspersky so it does not block the camera any more.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e00800e3002f302269c3b34f34faef8/a6d36/Kaspersky_Internet_Security_2015_Step1_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAABYlAAAWJQFJUiTwAAABgUlEQVQoz22SPU/CQBjH+7H8EobFUeLg4ifQxMnEwUkdHAyCCgPBekBNSvB1xQgWFUJEgw4YpGAJiteWu+u9mBaF0vjLs/3v9zzP5U6aD4dnQ6FIJAIAOPZIpVLZbFaW5WQyCQBIp9OZTEb5DymRSAAAms0mQsicZgBN07Qsy4KmFYgsDykeP1RVtdvtCiE45yII9+p/pFg0qqqqrusjeQRzu7Crl+HKmbV2Yat1TBzqy3/bSfuxmCzLnU5nLFPmTtNaeCaBsjUsP6Kl8+HBPRGCO2xajkb3FEXxT3Y8ebuMlwvYJuSogXaraOGSCE5HS03kXC6Xz58ahhGYfPKKF29IzyQM450nsn6HhWA0sHal8qBpWq/XG8vehTkmzkbVXq2RzWc0d4226li4badlTbstFot++S/nlDplwy7oVqNvF/QhpUwE5HJZK5VKPlkwJhgXDhUDm70b9KPPGHVPMy64V5OnGsntdhtj7P8GEJr9L/jWhS0Dfg7g4NuEvtT2+AE3tlCswtjEoAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e00800e3002f302269c3b34f34faef8/e4706/Kaspersky_Internet_Security_2015_Step1_EN.avif 230w", "/en/static/5e00800e3002f302269c3b34f34faef8/d1af7/Kaspersky_Internet_Security_2015_Step1_EN.avif 460w", "/en/static/5e00800e3002f302269c3b34f34faef8/69c29/Kaspersky_Internet_Security_2015_Step1_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e00800e3002f302269c3b34f34faef8/a0b58/Kaspersky_Internet_Security_2015_Step1_EN.webp 230w", "/en/static/5e00800e3002f302269c3b34f34faef8/bc10c/Kaspersky_Internet_Security_2015_Step1_EN.webp 460w", "/en/static/5e00800e3002f302269c3b34f34faef8/c1dc5/Kaspersky_Internet_Security_2015_Step1_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e00800e3002f302269c3b34f34faef8/81c8e/Kaspersky_Internet_Security_2015_Step1_EN.png 230w", "/en/static/5e00800e3002f302269c3b34f34faef8/08a84/Kaspersky_Internet_Security_2015_Step1_EN.png 460w", "/en/static/5e00800e3002f302269c3b34f34faef8/a6d36/Kaspersky_Internet_Security_2015_Step1_EN.png 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e00800e3002f302269c3b34f34faef8/a6d36/Kaspersky_Internet_Security_2015_Step1_EN.png",
              "alt": "Kaspersky 2014",
              "title": "Kaspersky 2014",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your camera´s video does not load, you might have to tell Kaspersky to trust the website...`}</p>
    <p><img parentName="p" {...{
        "src": "./Kaspersky_Internet_Security_02_EN.jpg",
        "alt": "Kaspersky 2014"
      }}></img></p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open the program Kaspersky by clicking the Kaspersky symbol on the right side of the windows task bar. Another way is to open the start menu and to choose the program directly. Once opened please click Settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/303bd9b659b311cecab53d04e29ef0fe/a6d36/Kaspersky_Internet_Security_2015_Step3_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAABYlAAAWJQFJUiTwAAACaElEQVQoz1WRy27TUBCG/ZC8Aqx5D1Ys6AbRBRKrICSqgsQGIegCdRHa2I2T+PhcbZ+bfezETmwntg9K3Is6+vRrNNKvf0bjxLkR5UZqLXWa5vl2V7f7Q7Pfj7Qnmvaetj3IIoeK3118xTPXkeUm2zc6Nzo33dAPwzD0fd91ffdUw2CP88Faa+M882JCN7nW2jnsO9udxta2h44KzVSKhSJCUaESlXFtpFmfKJRZZ/kmX1fFurqDxHn98ezV5Pzl5PzFp3dn375AmvyD6DoANxC5ECOWhCyGLAH0XiOZhlLPk2QeIufDxef3Py7eXk7eXE6+X/2KRDqnkUfoqCsWU64oVySRTGiciFhlsTaRNnchdupq2zd72x833zWtj5gL8TQANyC8XqxcgABmwQOARiLNsUqBlPMQO9KYoizbrjsMfbHdgohDIQGXSGqVFlyb5DkizanKkEqPyUVZrsuy3R+steWuDhn3EJkGYBbCJSRLRFaIPiYvQowjHmmDpL4DyKmbxlo7/qHc1isaz1l8i6nPovFOyhUTeoSn+ZiMlfYAfDQfX1XtasxVwBIf0wWJAI3DBwCJIEuSBzNS2l3B58m7JkBsBtH1ajUNwBJSyjgkUcS1VDlXGZeZkBkROhTSDfAz806q0PPYcknmc+r71PejxZL6PgdAIaQgOimMVyBaLLzpzKmb1lrbn8wpQZ+g9ydGP0nwm4W3MnYVd1N+K+ORmYp9I4HWC0rdq7/35jG50YpTrDAWCAoEOQw5fGpGBEYFY1tMoec7SCpiDDuSkdysq11VN9WuudexObGtm7SqSG6OrAso1X9CsCupUYetMQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/303bd9b659b311cecab53d04e29ef0fe/e4706/Kaspersky_Internet_Security_2015_Step3_EN.avif 230w", "/en/static/303bd9b659b311cecab53d04e29ef0fe/d1af7/Kaspersky_Internet_Security_2015_Step3_EN.avif 460w", "/en/static/303bd9b659b311cecab53d04e29ef0fe/69c29/Kaspersky_Internet_Security_2015_Step3_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/303bd9b659b311cecab53d04e29ef0fe/a0b58/Kaspersky_Internet_Security_2015_Step3_EN.webp 230w", "/en/static/303bd9b659b311cecab53d04e29ef0fe/bc10c/Kaspersky_Internet_Security_2015_Step3_EN.webp 460w", "/en/static/303bd9b659b311cecab53d04e29ef0fe/c1dc5/Kaspersky_Internet_Security_2015_Step3_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/303bd9b659b311cecab53d04e29ef0fe/81c8e/Kaspersky_Internet_Security_2015_Step3_EN.png 230w", "/en/static/303bd9b659b311cecab53d04e29ef0fe/08a84/Kaspersky_Internet_Security_2015_Step3_EN.png 460w", "/en/static/303bd9b659b311cecab53d04e29ef0fe/a6d36/Kaspersky_Internet_Security_2015_Step3_EN.png 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/303bd9b659b311cecab53d04e29ef0fe/a6d36/Kaspersky_Internet_Security_2015_Step3_EN.png",
              "alt": "Kaspersky 2014",
              "title": "Kaspersky 2014",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: In the window Protection please select the setting Web Anti-Virus.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b45c63e443f0e66a4c4495812b0d0b90/a6d36/Kaspersky_Internet_Security_2015_Step4_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAABYlAAAWJQFJUiTwAAABx0lEQVQoz42R727bIBTF/f5vsz3Aug9Vq31c1yY2YBuMAfMfjLHjrNImJ11Xtck09NPRvaBzD4iCOTeMo1RaKCWU8jFOeT6s60XW9SiDb5R4+vypffhRmDnH52NKKaY0psnHGGL8dX3x4B45YSlYY4uGUIBq2LSoxTbEccphTCFNm56KeN6Zcsg5zcthPR5/PuuciVQFHWQ/yF5IKgSTSijNpOJSC2WENlzpwRguFTUWW+dTDlP2U1YpYSELiPuyxlVDhPGDDcL4M/zUShcHGzZMeD3lxhk/IiqKmvKasidYlzUuGwIwrdoOtLRqOswGOujXcW/nShdhx4um54RL0HYAU9QxiHtIeoDpWQmXb6/z3gwJRaTfo3aH2h1szmlEqE6ojiuu3b+Sq7Z7BGAH0UNZVS1myrw80oaPme/NhEtuHNOWacs33EXDZTNV1oSk3Kj8KK5EXTWjfYkqAAGqSzBw+T/+v+bv99/ubr7e397efbmBe4B6gTq2QTnqOCRbDUl/1u1fO4YII1wB0hcqjCEvMc9+2kjzcuKwaV7+tC+M8xLyHPOclpVKUxCtqffM+957Yq1LaZxyvMQ4ZRkjtpY4S7ytufgNT3I3JACNKdcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b45c63e443f0e66a4c4495812b0d0b90/e4706/Kaspersky_Internet_Security_2015_Step4_EN.avif 230w", "/en/static/b45c63e443f0e66a4c4495812b0d0b90/d1af7/Kaspersky_Internet_Security_2015_Step4_EN.avif 460w", "/en/static/b45c63e443f0e66a4c4495812b0d0b90/69c29/Kaspersky_Internet_Security_2015_Step4_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b45c63e443f0e66a4c4495812b0d0b90/a0b58/Kaspersky_Internet_Security_2015_Step4_EN.webp 230w", "/en/static/b45c63e443f0e66a4c4495812b0d0b90/bc10c/Kaspersky_Internet_Security_2015_Step4_EN.webp 460w", "/en/static/b45c63e443f0e66a4c4495812b0d0b90/c1dc5/Kaspersky_Internet_Security_2015_Step4_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b45c63e443f0e66a4c4495812b0d0b90/81c8e/Kaspersky_Internet_Security_2015_Step4_EN.png 230w", "/en/static/b45c63e443f0e66a4c4495812b0d0b90/08a84/Kaspersky_Internet_Security_2015_Step4_EN.png 460w", "/en/static/b45c63e443f0e66a4c4495812b0d0b90/a6d36/Kaspersky_Internet_Security_2015_Step4_EN.png 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b45c63e443f0e66a4c4495812b0d0b90/a6d36/Kaspersky_Internet_Security_2015_Step4_EN.png",
              "alt": "Kaspersky 2014",
              "title": "Kaspersky 2014",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: In the Web Anti-Virus settings please click on Advanced Settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/a6d36/Kaspersky_Internet_Security_2015_Step5_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB3UlEQVQoz33O2VLbMBSAYb//27SP0AEKpI3J4shEtiTL2izZWrzEDly0E5wWJkzQfHNuNL+OIu6s6lptGqmNrLQLbTcMXX9yGKfp+PLR8eW18g4pufv+DW+SyPS9ez2GtrXeOx9qa13wPgQXwnQ8/vl0mGs2nJat09pEOeUFk4SrWWW9tuHEharx6pPadS4MPgwA0SjNSbwFv9dJnKTxFuwxzQqWl+IZF4WoqiZcxLJ2zFjV+G2GI5CTdbrfQbROYYpIISrM5AyVAjNJxPlT8xXhShhb2XCK94SuwClGpUCUZ5TllP+PTz0/v0W4mp8Txuo5plJDxhcQLvYwwUVOOWKysuEz1fh5itqdNzNjEZeLBCwSsNylhTLKBmmsrN017zFM0vvl8mbxePtr8ePx4Wn1VCnDjRXX+/c4vX+Ib27Xd3erN9u7nwWmWFa4FFw3X8QbiCNA6NMuxVyJ2nFjmW6oMqXUpTJfb95AFOWUpYiADO8gAhnePMME5juI0pxc+/l7jJjc42K5BfEWPKMCczUjomK6ucD/kcbFIIuMC2E4GOdrH9px6sfjcJrTME7dYbzQDmPoD6E/9OOUMxkRraltmLPMWlKbJoTQdn7W9R+FrlfO49qQuia2zrj8Cwr3LanqajYTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf8e1b45927df89d22880aa46fc2c9a9/e4706/Kaspersky_Internet_Security_2015_Step5_EN.avif 230w", "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/d1af7/Kaspersky_Internet_Security_2015_Step5_EN.avif 460w", "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/69c29/Kaspersky_Internet_Security_2015_Step5_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bf8e1b45927df89d22880aa46fc2c9a9/a0b58/Kaspersky_Internet_Security_2015_Step5_EN.webp 230w", "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/bc10c/Kaspersky_Internet_Security_2015_Step5_EN.webp 460w", "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/c1dc5/Kaspersky_Internet_Security_2015_Step5_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf8e1b45927df89d22880aa46fc2c9a9/81c8e/Kaspersky_Internet_Security_2015_Step5_EN.png 230w", "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/08a84/Kaspersky_Internet_Security_2015_Step5_EN.png 460w", "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/a6d36/Kaspersky_Internet_Security_2015_Step5_EN.png 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bf8e1b45927df89d22880aa46fc2c9a9/a6d36/Kaspersky_Internet_Security_2015_Step5_EN.png",
              "alt": "Kaspersky 2014",
              "title": "Kaspersky 2014",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Please click on Configure trusted web pages.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f69134ed6e0e6358775b61aeae1afc77/a6d36/Kaspersky_Internet_Security_2015_Step6_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAABYlAAAWJQFJUiTwAAACT0lEQVQoz43R72vTQBgH8Pzv0uELGW4iKggTQdgb0eKQFuc6N11Z55ZZmyZNk9xdk7v8aHLJ5deSLMnJOgez+GJfnhfHw3245+EEl0VeGvtB4PpLd+mxOCmKK3uZvOhH2/30eT/Z/JxudLONbtrpJp1u8ugD63xk253e7s6R4GcprQrGYhpFNGKeT5OU6Yi+7kVvDpO3g/jVl/TJXv60V2z1y61e+axfPd7LdsVya0cUgEWgRRB2ILYRdtwg8iOGnISl1XXmFolXZt4Vs9q65HdpmoZzfvKLCiqAF5Pp+W9JlBRRkmdgYZjWVHcwLZdl7uY5K6u64df136prnhbXFa+Hoi/MDDSWVWmuj2VV0SHEzsJ2JN2lcc152zRty1v+b+rmpnMi+oKGzLE8kzUATGKYWF9gaBFJd1aYt+vwduyb7lAMBMtdqiY5m2tnc00CSF9YCBNA4gdh4lHDIj8n07PJVJzICLteGGlW+CCsY2do6Meq+l2dHc0UESKPhppFadys8M29qqqyLM/zfB1PoTnSNBGACwOcG8alARfEloEX3sN1XRdlWZTlOlYNdDmRoWnbHiVuYDk+9pYq8h80NjCxokNZA9LckDUwVuaypl1M0RpuV7k9NXWzwr4AsTMDSJSUS0lRDASwg4gtG97dzv95+e6fA8EPWZhkbhB6NIrzIs6u8qKwgzxMKs7rtq05v18Nr/KGerygwxMoEBoQFrpx5LDIimiUJlWVQ8I+HZkHp/BgZB6MrP4P8PV0MRhZg3N7sD/dfzc4fv/t5ebhHxi/NFPRKsSdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f69134ed6e0e6358775b61aeae1afc77/e4706/Kaspersky_Internet_Security_2015_Step6_EN.avif 230w", "/en/static/f69134ed6e0e6358775b61aeae1afc77/d1af7/Kaspersky_Internet_Security_2015_Step6_EN.avif 460w", "/en/static/f69134ed6e0e6358775b61aeae1afc77/69c29/Kaspersky_Internet_Security_2015_Step6_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f69134ed6e0e6358775b61aeae1afc77/a0b58/Kaspersky_Internet_Security_2015_Step6_EN.webp 230w", "/en/static/f69134ed6e0e6358775b61aeae1afc77/bc10c/Kaspersky_Internet_Security_2015_Step6_EN.webp 460w", "/en/static/f69134ed6e0e6358775b61aeae1afc77/c1dc5/Kaspersky_Internet_Security_2015_Step6_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f69134ed6e0e6358775b61aeae1afc77/81c8e/Kaspersky_Internet_Security_2015_Step6_EN.png 230w", "/en/static/f69134ed6e0e6358775b61aeae1afc77/08a84/Kaspersky_Internet_Security_2015_Step6_EN.png 460w", "/en/static/f69134ed6e0e6358775b61aeae1afc77/a6d36/Kaspersky_Internet_Security_2015_Step6_EN.png 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f69134ed6e0e6358775b61aeae1afc77/a6d36/Kaspersky_Internet_Security_2015_Step6_EN.png",
              "alt": "Kaspersky 2014",
              "title": "Kaspersky 2014",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: In the following window click on Add.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/24e1d1adba46a279a2d0a4b102522aa1/a6d36/Kaspersky_Internet_Security_2015_Step7_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAABYlAAAWJQFJUiTwAAACQklEQVQoz43QzW/TMBjA4f7vbFc+JhDSLoMDYreB0KCDwdoxbWLdmlKSJk2c2G4c5zu2k7RL2zRGFYwNDYm9+skHS4/02q1Q8KjMkzQNkziMI5EXVXXlx8XzA7bVLp59LB6+LzbeFJtvf/dgT2zsiceb7d2dbiuZltmiEiLPOM+4iJKsKAVA2fYBf9ktdg7Z9qfyUbt6crsP87Ymt14oLUgoIhR7AfJ87AVhyhMucJDH0yspi2qW1nUl/57VqpFSnn5nLROivjq6/KEpmqFo+hhOHJeowM9KmdsAdo7h8UlwftmgydJGSxsubDifLda4n7TGDh7qpmbZQ900bIS8YOIHGgj4VE4D6moj37Ji2xaETH2/YWyVJPV8jc+UtAWwO9THOoDQpY7r2RMPEaoCn5dNXuYJY1LK5rrba68xCWPTpT0L9CygQWxPCPYopIIVq3o5j6KoXi7Xomn+dINplDmEXqijnjpSVB17YcQ4ICzL61/k7txg2wvOHPuraZ6Y4+OxoSAcZQyQLMtX/8cj5J4DoEDYd+Cl4wwcNKG+DiN2H2w6eKDqyPX9KKNhSoLEi2ITJ/daG7qeYSMdQM1ydACHhqUD0B/hf+C7H4a8YAyxohkDzTAcDL0AU193ortvbq7PG5wwwYppmLIo4/msyqdXs6ry0xkrFlLWTVNLWUu5klUpebxuJlbry+ZsELVollLBwpwHghOe8bJYLGaYindd0r0gX76Ro3PvqEe7B+rn3e7h605nv79/4hyeWk9f6T8B1RQv7gSJFbUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24e1d1adba46a279a2d0a4b102522aa1/e4706/Kaspersky_Internet_Security_2015_Step7_EN.avif 230w", "/en/static/24e1d1adba46a279a2d0a4b102522aa1/d1af7/Kaspersky_Internet_Security_2015_Step7_EN.avif 460w", "/en/static/24e1d1adba46a279a2d0a4b102522aa1/69c29/Kaspersky_Internet_Security_2015_Step7_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/24e1d1adba46a279a2d0a4b102522aa1/a0b58/Kaspersky_Internet_Security_2015_Step7_EN.webp 230w", "/en/static/24e1d1adba46a279a2d0a4b102522aa1/bc10c/Kaspersky_Internet_Security_2015_Step7_EN.webp 460w", "/en/static/24e1d1adba46a279a2d0a4b102522aa1/c1dc5/Kaspersky_Internet_Security_2015_Step7_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24e1d1adba46a279a2d0a4b102522aa1/81c8e/Kaspersky_Internet_Security_2015_Step7_EN.png 230w", "/en/static/24e1d1adba46a279a2d0a4b102522aa1/08a84/Kaspersky_Internet_Security_2015_Step7_EN.png 460w", "/en/static/24e1d1adba46a279a2d0a4b102522aa1/a6d36/Kaspersky_Internet_Security_2015_Step7_EN.png 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/24e1d1adba46a279a2d0a4b102522aa1/a6d36/Kaspersky_Internet_Security_2015_Step7_EN.png",
              "alt": "Kaspersky 2014",
              "title": "Kaspersky 2014",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: Please type in the IP address of your camera. It is very important to set the http:// before and the wildcard /* after the IP address.`}</p>
    <p><strong parentName="p">{`Step 7`}</strong>{`: Finally, please reload the web interface of your IP camera. This can be done by either clicking the refresh button next to the address bar of your web browser or you can close your web browser and type in the IP address of your IP camera again.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0c373104e02fa7522940ccb36bb78147/a6d36/Kaspersky_Internet_Security_2015_Step8_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAABYlAAAWJQFJUiTwAAACqklEQVQozwGfAmD9AF5fX1JTU5KUlp2hpqGmqp2ip5mepKGnrZuhpZieo56lrKClq6OlqKCipKCgoJ+goKCipZ+gop6enZ+enAChoaGvrq0+S143V4FLcJxQhLZFhsI7ebdFdqs+aJw9aJxQc51jdYpXaHtQaIJGX31DYoczVX4rSnEsQ10AnJycuLi3RVt1KlSIOmSUOWaaN3e1PIzOQYjHQHu3Q3q0P3OsS3akV3eaU3ylSXOdPV2BRWSHPmqeKliSAJ2dnbi5ukpkgC5IZzhXejRej0OFvkie20ib10qi3kGS0EGJw0OLxlGRxVR0k1BkeElcb0RVZ1Jnf0tddACbm5uxsbFYdI5EW3BKaYhPfKZao9RRmMxOlMlTpttXsOVXseZkrd1smLtNZHpNY3c5SVc9S1g/S1VGU2EAnJycsbGxboCOVmp6d5SqgK3PeazSc5/BdZ7Ah6rIl8HfkcXlkMDhlcDdYoCVOElXPU9cVWl5TWBwNEFNALi4uL29vXmGjmV4h4WjvICeuYaiuouqw5SyzJm71p2/2pi61ICet3KOpE1jdSs8SiMyPTFDVEhcb0FPYADPz8/R0dFtfYZsgZKCmq15kqd4kad5k6ttkLFmi61niqplhaNUa4BOZXdOYnNAU2I2SVYxQU1UaHlwh5sAy8vLzc3NeYePboGOb4CNfI6dcoWYUmd9a4GWdoufjaO1kam8nrXEp7/OpLzMobjIn7XHj6m/eJStdY2mAMzMzM3NzV1pcFRkbmBtdkdRW0lYamuAk6K8zazF1q/H2anC1KC5zKjC1KTA05m3zI2pwICdtXeRqmeDmgDLy8vQ0NBha3AvOD5DTVZZZXCHna2fuMikvMymvs6kvM2iusyatMeatsiWssWQq7+HpbmAm7F5kqhtiZ9jCmBn+hKjWgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c373104e02fa7522940ccb36bb78147/e4706/Kaspersky_Internet_Security_2015_Step8_EN.avif 230w", "/en/static/0c373104e02fa7522940ccb36bb78147/d1af7/Kaspersky_Internet_Security_2015_Step8_EN.avif 460w", "/en/static/0c373104e02fa7522940ccb36bb78147/69c29/Kaspersky_Internet_Security_2015_Step8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0c373104e02fa7522940ccb36bb78147/a0b58/Kaspersky_Internet_Security_2015_Step8_EN.webp 230w", "/en/static/0c373104e02fa7522940ccb36bb78147/bc10c/Kaspersky_Internet_Security_2015_Step8_EN.webp 460w", "/en/static/0c373104e02fa7522940ccb36bb78147/c1dc5/Kaspersky_Internet_Security_2015_Step8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c373104e02fa7522940ccb36bb78147/81c8e/Kaspersky_Internet_Security_2015_Step8_EN.png 230w", "/en/static/0c373104e02fa7522940ccb36bb78147/08a84/Kaspersky_Internet_Security_2015_Step8_EN.png 460w", "/en/static/0c373104e02fa7522940ccb36bb78147/a6d36/Kaspersky_Internet_Security_2015_Step8_EN.png 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0c373104e02fa7522940ccb36bb78147/a6d36/Kaspersky_Internet_Security_2015_Step8_EN.png",
              "alt": "Kaspersky 2014",
              "title": "Kaspersky 2014",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      